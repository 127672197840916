import React from "react"

import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon, PhoneIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const navRoutes = [
  { to: "/services", label: "Our services" },
  { to: "/projects", label: "Projects" },
  { to: "/testimonials", label: "Testimonials" },
]

export default function Example() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-20">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-600">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <div className="block lg:hidden h-8 w-auto">
                      <StaticImage
                        src="../images/logo-mobile.png"
                        width={30}
                        alt="Logo"
                      />
                    </div>
                    <div className="hidden lg:block w-auto">
                      <StaticImage
                        src="../images/logo-alt.png"
                        height={35}
                        alt="Logo"
                      />
                    </div>
                  </Link>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {navRoutes.map(({ to, label }) => (
                    <Link
                      key={`desktopNav${to}`}
                      to={to}
                      activeClassName="border-teal-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    >
                      {label}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link
                    to="/contact"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 shadow-sm hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
                  >
                    <PhoneIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Contact us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navRoutes.map(({ to, label }) => (
                <Link
                  key={`mobileNav${to}`}
                  to={to}
                  activeClassName="bg-teal-50 border-teal-600 text-teal-600 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-600 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {label}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
